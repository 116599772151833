<template>
  <div>
    <span>{{ labelInput }}</span>
    <div class="table-header">
      <b-form-input
        v-show="!!filterable"
        class="search-input mb-3"
        v-model="search"
        :placeholder="placeholder"
        debounce="500"
        trim
      />
      <soi-select
        v-if="mostrarSelecao"
        :name="name"
        class="select"
        :options="options"
        :selected="selected"
        @change="$emit('fetchData', $event)"
      />
      <soi-select
        v-show="!!showPerPage"
        class="perpage-select"
        placeholder=""
        :allow-empty="false"
        :selected="pageSize"
        :options="pageSizeOptions"
        @change="handlePageSizeChange"
      />
    </div>

    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover">
        <thead>
          <template v-if="!!fields.length">
            <tr>
              <th v-if="selectable" class="text-center check-column">
                <soi-checkbox
                  class="mb-0 pl-2"
                  :value="allRowsIsChecked"
                  :disabled="!selectMultiple"
                  @change="toggleCheckAll"
                />
              </th>
              <th v-for="(field, index) in fields.filter((f => !f.visible))"
                :key="index" :class="{ [field.class]: true }">
                {{ field.label }}
                <i @click="sort(field.key,true)" class="fas fa-caret-down"
                  v-if="showIconSort(field.key) && hasSortIcon"></i>

                <i @click="sort(field.key,false)" class="fas fa-caret-up"
                  v-if="showIconSort(field.key) && hasSortIcon"></i>
              </th>
            </tr>
          </template>
        </thead>
        <tbody v-if="!!items.length">
          <template v-for="(row, index) in items">
            <!-- <tr :key="index" @click="toggleItemByLine(row, $event)"> -->
            <tr :key="index">
              <td v-if="selectable" class="text-center check-column" :key="'check-' + index"
                  @click="toggleItemByCell(row, $event)">
                <soi-checkbox
                  class="mb-0 pl-2"
                  :value="isSelected(row)"
                />
              </td>

              <template v-for="(field) in fields.filter((f => !f.visible))">
                <td
                  v-if="field.isTemplate"
                  :key="index + field.key"
                  :class="{
                    [field.class]: true,
                  }"
                  @click="toggleItemByCell(row, $event, field)"
                >
                <div v-if="field.key == 'detalhes' && hasButtonDetails">
                  <soi-button
                    icon="caret-down"
                    variant="info"
                    class="p-0"
                    @click="toggleCollapse(index)"
                  >
                  </soi-button>
                </div>
                <slot :name="field.key" :item="row" />
                </td>
                <td
                  v-else-if="field.isStatus"
                  :key="index + field.key"
                  :class="{
                    [field.class]: true,
                  }"
                >
                <slot :name="field.key" :item="row" />
                </td>
                <td
                  id="hideText"
                  v-else
                  :key="index + field.key"
                  :class="{
                    [field.class]: true,
                  }"
                  @click="toggleItemByCell(row, $event, field.key)"
                >
                <i v-if=" row[field.key] && field.isTooltip"
                  :id="`popover-${index + field.key}`"
                  class="fa-solid fa-circle-info"
                  style="color: #ccc;"
                  >
                <b-popover
                  :target="`popover-${index + field.key}`"
                  triggers="hover"
                  placement="right"
                  >
                  {{ row[field.key] }}
                </b-popover>
              </i>
                  {{ row[field.key] }}
                </td>
              </template>
            </tr>
            <div
              v-if="hasCollapse"
              :key="`collapse-${index}`"
              :class="{
                'row__collapse': true,
                'show': currentCollapse === index,
              }"
            >
              <td colspan="100%" v-if="visibleRow">
                <portal-target name="paged-table-collapse-content" :id="`portal-${index}`"/>
              </td>
            </div>
          </template>
        </tbody>
        <tbody v-else>
          <tr v-show="!loading">
            <td colspan="100%" align="center">
              Nenhum registro encontrado!
            </td>
          </tr>
        </tbody>
        <soi-loader v-if="loading" small />
      </table>
    </div>

    <div class="table-footer">
      <span v-if="!!items.length">{{showingRange}}</span>
      <b-pagination
        v-if="!!rowCount"
        align="right"
        :value="currentPage"
        :per-page="pageSize"
        :total-rows="rowCount"
        @change="handlePageChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import ITableField from '../models/Interfaces/ITableField';
import IDictionary from '../models/Interfaces/IDictionary';
import IInputChangePayload from '../models/Interfaces/IInputChangePayload';
import PagedRequest, { IPagedRequest } from '../models/Paginacao/PagedRequest';
import PagedResult, { IPagedResult } from '../models/Paginacao/PagedResult';
import ComponenteBase from './ComponenteBase.vue';
import RedirecionamentoFiltroModule from '../store/modules/RedirecionamentoFiltroModule';
import { CHANGE_VALUES } from '../store/actions/RedirecionamentoFiltroActions';

@Component
export default class PagedTable extends ComponenteBase {
  @Prop({ type: [Object, Array], default: () => new PagedResult() })
  private data!: IPagedResult;

  @Prop({ type: Array, default: () => [] })
  public fields!: Array<any>;

  @Prop({ type: Boolean, default: true })
  public filterable!: boolean;

  @Prop({ type: Boolean, default: false })
  public selectable!: boolean;

  @Prop({ type: Boolean, default: false })
  public selectMultiple!: boolean;

  @Prop({ type: Boolean, default: true })
  public showPerPage!: boolean;

  @Prop({ type: Boolean, default: false })
  public loading!: boolean;

  @Prop({ type: Boolean, default: false })
  public hasCollapse!: boolean;

  @Prop({ type: Boolean, default: false })
  public hasButtonDetails!: boolean;

  @Prop({ type: Boolean, default: false })
  public hasSortIcon!: boolean;

  @Prop({ type: Boolean, default: false })
  private hasESocialButton!: boolean;

  @Prop({ type: Boolean, default: false })
  private hasLoteESocialButton!: boolean;

  @Prop({ type: String, default: '' })
  private labelInput!: string;

  @Prop({ type: String, default: 'Procure por algum registro...' })
  public placeholder!: string;

  @Prop({ type: Boolean, default: false })
  public mostrarSelecao!: boolean;

  @Prop({ type: Array, default: () => [] })
  public options!: IDictionary[];

  @Prop({ default: '' })
  private selected!: number | string;

  @Prop({ default: '' })
  public name!: string;

  public currentCollapse: number | null;

  public pageSizeOptions: Array<IDictionary>;

  public search: string;

  private atualCollapse: string;

  public selectedItems: Array<any>;

  public itemAdicionadoASelecao: any;

  private sortingFields: IPagedRequest;

  public visibleRow: boolean;

  private pagina: number;

  private tamanhoPagina: number;

  get columnCheck() {
    return 'columnCheck';
  }

  get items(): Array<any> {
    return this.data.results || [];
  }

  get currentPage(): number {
    return this.data.currentPage || 1;
  }

  get rowCount(): number {
    return this.data.rowCount || 0;
  }

  get pageSize(): number {
    return this.data.pageSize;
  }

  get showingRange() {
    const { firstRowOnPage, lastRowOnPage, rowCount } = this.data;
    return `Mostrando de ${firstRowOnPage} até ${lastRowOnPage} de ${rowCount} registros`;
  }

  get allRowsIsChecked() {
    // eslint-disable-next-line arrow-body-style
    return !!this.selectMultiple && !!this.items.length && !!this.items.every((item) => {
      return !!this.selectedItems.find(({ id }) => id === item.id);
    });
  }

  constructor() {
    super();
    this.search = '';
    this.selectedItems = [];
    this.currentCollapse = null;
    this.atualCollapse = '';
    this.visibleRow = true;
    this.pagina = 1;
    this.tamanhoPagina = 5;

    const item = this.options.find((option) => String(option.key) === String(this.selected));
    if (item) {
      this.itemAdicionadoASelecao = item;
    } else {
      this.itemAdicionadoASelecao = '';
    }

    this.pageSizeOptions = [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ];

    this.sortingFields = { page: 1 };
  }

  @Watch('selectedItems')
  private handleSelectedItemsChange() {
    this.$emit('selectedItems', this.selectedItems);
  }

  @Watch('search')
  private handleSearchChange() {
    const query: IPagedRequest = {
      page: 1,
      pageSize: this.pageSize,
      termoDigitado: this.search,
    };

    this.$emit('fetchData', query);
  }

  @Watch('pageSize')
  public enviaTamanhoDaPaginaParaFiltro() {
    RedirecionamentoFiltroModule[CHANGE_VALUES]({ field: 'pageSize', value: this.pageSize });
  }

  @Watch('selected')
  private changeSelectedValue() {
    Vue.nextTick(() => {
      const item = this.options.find((option) => String(option.key) === String(this.selected));
      if (item) {
        this.itemAdicionadoASelecao = item;
      } else {
        this.itemAdicionadoASelecao = '';
      }
    });
  }

  @Watch('options')
  private onChangeOptions() {
    const item = this.options.find((option) => String(option.key) === String(this.selected));
    if (item) {
      this.itemAdicionadoASelecao = item;
    } else {
      this.itemAdicionadoASelecao = '';
    }
  }

  public showIconSort(field: string): boolean {
    const colunasSemOrdenacao = ['status', 'acoes', 'cpf', 'cnpj', 'actions', 'documento', 'detalhes', 'totalDeFuncionarios', 'role'];
    return (colunasSemOrdenacao.indexOf(field) < 0);
  }

  private setVisible() {
    this.visibleRow = true;
  }

  public sort(field: string, orderDesc: boolean) {
    this.visibleRow = false;
    this.sortingFields = {
      page: 1,
      orderBy: field.toLowerCase(),
      orderDesc,
      pageSize: this.pageSize,
    };
    this.$emit('sortData', this.sortingFields);
  }

  get routeName(): string {
    if (this.$route) {
      return this.$route.name || '';
    }

    return '';
  }

  private created() {
    const query = new PagedRequest(1);
    this.$emit('fetchData', query);
  }

  public handlePageSizeChange(payload: IInputChangePayload) {
    this.tamanhoPagina = payload.value as number;
    const query: IPagedRequest = {
      page: 1,
      pageSize: payload.value as number,
      termoDigitado: this.search,
    };

    this.$emit('fetchData', query);
  }

  public handlePageChange(page: number) {
    this.pagina = page;
    const query: IPagedRequest = {
      page,
      pageSize: this.pageSize,
      termoDigitado: this.search,
    };

    if (this.sortingFields.orderBy) {
      query.orderBy = this.sortingFields.orderBy;
      query.orderDesc = this.sortingFields.orderDesc;
    }

    this.$emit('fetchData', query);
  }

  public isSelected(row: any): boolean {
    if (row.tabela !== null) {
      return !!this.selectedItems.find(({ id, tabela }) => id === row.id && tabela === row.tabela);
    }
    return !!this.selectedItems.find(({ id, tabela }) => id === row.id);
  }

  public toggleCollapse(index: number, collapse: string = '') {
    this.visibleRow = true;
    if (this.currentCollapse === index) {
      this.currentCollapse = this.atualCollapse === collapse ? null : index;
      this.atualCollapse = collapse;
    } else {
      this.$emit('showCollapse', { index });
      this.currentCollapse = index;
      this.atualCollapse = collapse;
    }
  }

  public selecionarItens(itens) {
    this.selectedItems = itens;
  }

  public filtrarItensSelecionadosPorCondicao(condicao) {
    this.selectedItems = this.selectedItems.filter(condicao);
  }

  public limparSelecao() {
    this.selectedItems = [];
  }

  public toggleItemByCell(row: any, event: PointerEvent, field?: ITableField) {
    if (field?.disableClick) {
      return;
    }

    event.preventDefault();
    if (event.type === 'click') {
      const index = this.selectedItems.findIndex(({ id }) => id === row.id);

      if (index === -1) {
        if (this.selectedItems.length === 1 && !this.selectMultiple) {
          this.selectedItems = [];
        }
        this.$emit('selecionado', row);
        this.selectedItems.push(row);
      } else {
        this.$emit('desmarcado', row);
        this.selectedItems = this.selectedItems.filter(({ id }) => id !== row.id);
      }
    }
  }

  public toggleCheckAll(payload: IInputChangePayload) {
    if (!this.selectMultiple) return;

    if (payload.value) {
      this.$emit('selecionadoTodos', this.items);
      this.selectedItems = this.items;
    } else {
      this.$emit('desmarcadoTodos', this.items);
      this.selectedItems = [];
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 0;

  .search-input {
    width: 100%;
  }

  .perpage-select {
    justify-self: flex-end;
    margin-left: 20px;
    width: 90px;
  }

  .select {
    justify-self: flex-end;
    margin-left: 20px;
    width: 300px;
  }
}

#hideText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50ch;
}

.table-responsive {
  max-width: 100%;
  min-height: 114px;
  padding: 0 1px;
  margin-bottom: 1rem;
  overflow-y: hidden;

  .table {
    margin: 0;

    .check-column {
      width: 10px;
    }

    .row__collapse {
      display: none;
    }

    .row__collapse.show {
      display: table-row;
      overflow-y: hidden;
    }
  }

  .table-bordered {
    border: 1px solid #dee2e6 !important;
  }
}

.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  & > span {
    font-size: .875em;
  }
}
</style>
